@import '../../../common/styles/variables.scss';
@import '../../../common/mixins/inputs.scss';

:host {
  display: block;
  position: relative;

  @media #{$mobile} {
    position: unset;
  }

  @include default-css-variables;
  width: 100%;
}

label {
  @include input-label;
  flex-direction: column;
  gap: 4px;
  font-family: var(--nylas-font-family);
  font-size: 16px;
  color: var(--nylas-base-800);

  p {
    margin: 0;
  }

  .error {
    color: var(--nylas-error);
  }
}

.color-picker-container {
  @include textfield;
  width: -moz-available;
  width: -webkit-fill-available;
  padding-left: 0;
  gap: 0;
  overflow: hidden;

  input {
    display: flex;
    align-items: center;
    height: 100%;
    appearance: none;
    border: none;
    border-right: 1px solid var(--nylas-base-200);
    background: transparent;

    &::-webkit-color-swatch {
      min-width: 30px;
      border-radius: 0.25rem;
      border: 1px solid var(--nylas-base-200);
      margin: 0.25rem;
    }

    &::-moz-color-swatch {
      min-width: 30px;
      border-radius: 0.25rem;
      border: 1px solid var(--nylas-base-200);
      margin: 0.25rem;
    }
  }

  &.error {
    border: 1px solid var(--nylas-error);
  }

  &:hover,
  &:active {
    outline: 1px solid var(--nylas-primary);
  }

  &:active {
    outline: 2px solid var(--nylas-primary);
  }

  label {
    width: 100%;
  }
}

.labelBtn {
  color: var(--nylas-base-800);
  padding: 14px;
  font-size: 1rem;
  font-family: var(--nylas-font-family);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
  background: transparent;
  border: none;

  &.error {
    border: 1px solid var(--nylas-error);
  }

  &.focus {
    background: transparent;
  }

  &:hover,
  &:active {
    outline: 1px solid var(--nylas-primary);
  }

  span {
    &.chevron {
      display: flex;
      align-self: center;
    }

    &.open {
      transform: rotate(90deg);
    }

    &.closed {
      transform: rotate(270deg);
    }

    &.selected-option {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 144px;
      font-size: 14px;
      line-height: 20px;

      @media #{$mobile} {
        max-width: 124px;
        font-size: 16px;
      }
    }
  }
}
