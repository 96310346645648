@import '../../../common/styles/variables.scss';

:host {
  display: block;
  position: relative;
  @media #{$mobile} {
    position: unset;
  }
  @include default-css-variables;
}

.dropdown {
  display: inline-block;
}

.dropbtn {
  color: var(--nylas-base-800);
  padding: 10px;
  font-size: 1rem;
  font-family: var(--nylas-font-family);
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  background: transparent;
  border: none;
  border-radius: var(--nylas-border-radius-2x);
  width: 50px;
  padding: 14px 16px;
  border-radius: var(--nylas-border-radius-2x);
  &.focus {
    background: transparent;
  }
  &:hover,
  &:active {
    outline: 1px solid var(--nylas-primary);
  }
  &:active {
    outline: 2px solid var(--nylas-primary);
  }
  span {
    &.chevron {
      display: flex;
      align-self: center;
    }
    &.open {
      transform: rotate(90deg);
    }
    &.closed {
      transform: rotate(270deg);
    }
    &.selected-option {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 144px;
      font-size: 14px;
      line-height: 20px;
      @media #{$mobile} {
        max-width: 124px;
        font-size: 16px;
      }
    }
  }
}

.dropdown-content {
  display: block;
  margin-top: 0.5rem;
  background-color: var(--nylas-base-0);
  max-width: 306px;
  width: max-content;
  max-height: 336px;
  overflow: auto;
  z-index: 1;
  border-radius: 4px;
  position: absolute;
  @media #{$mobile} {
    right: 0;
    width: 325px;
    max-width: unset;
  }
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.search-box {
  border-bottom: 1px solid var(--nylas-base-200);
  padding: 10px;
  position: sticky;
  top: 0;
  background: var(--nylas-base-0);
  .icon {
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
    color: var(--nylas-base-300);
  }
}

.dropdown-content ul {
  padding: 0;
  list-style-type: none;
  color: var(--nylas-base-900);
  max-height: 336px;
  li {
    padding: 16px, 12px, 16px, 12px;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    cursor: pointer;
    &:hover,
    &:focus {
      background-color: var(--nylas-base-100);
    }
  }
}

.dropdown-content .selected {
  background-color: #e7e7e7;
}

input[type='text'] {
  width: -webkit-fill-available;
  padding: inherit;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: sticky;
  background: no-repeat scroll 7px 7px;
  padding-left: 30px;
  background-size: 16px 16px;
}
