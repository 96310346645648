@import '../../../common/styles/variables.scss';
@import '../../../common/mixins/inputs.scss';

:host {
  display: block;
  height: auto;
  @include default-css-variables;
  width: 100%;
}

label {
  @include input-label;
  flex-direction: column;
  gap: 4px;
  font-family: var(--nylas-font-family);
  font-size: 16px;
  color: var(--nylas-base-800);

  p {
    margin: 0;
  }

  .error {
    color: var(--nylas-error);
  }
}

.input_wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

input[type='text'],
input[type='email'] {
  @include textfield;
  width: -moz-available;
  width: -webkit-fill-available;
}
