import { Component, Element, Event, EventEmitter, h, Listen, Prop, State } from '@stencil/core';

/**
 * The `input-color-picker` component is a dropdown that allows users to select an accent color from either RGB inputs or an interactive color picker and converts them to a hex value.
 * This component is used in the scheduling form to input dropdown type inputs.
 * @part icp_color-picker - The dropdown container
 * @part icp_color-picker-button - The dropdown button
 * @part icp_color-picker-button-selected-label - The selected option label
 * @part icp_color-picker-label - The dropdown label
 */
@Component({
  tag: 'input-color-picker',
  styleUrl: 'input-color-picker.scss',
  shadow: true,
})
export class InputColorPicker {
  @Element() el!: HTMLElement;
  private readonly componentType: string = 'color-picker';

  private inputRef?: HTMLInputElement;

  // Props
  /**
   * The name of the dropdown
   */
  @Prop() name!: string;
  /**
   * The default selected color
   */
  @Prop({ attribute: 'default-selected-color' }) defaultSelectedColor?: string;

  // States
  /**
   * The selected option
   */
  @State() selectedColor: string | undefined = this.defaultSelectedColor;
  /**
   * The open state of the dropdown
   */
  @State() isOpen: boolean = false;
  /**
   * The aria-activedescendant attribute for the listbox element to indicate the currently active
   * option in the list box to screen readers. The value of aria-activedescendant is the ID of
   * the active option.
   */
  @State() ariaActivedescendant: string = '';

  /**
   * The error message to display when the value is empty or null and the dropdown is required
   */
  @State() errorMessage: string = '';

  // Events
  /**
   * This event is fired when the selected option is changed
   */
  @Event({ bubbles: true, composed: true }) nylasFormDropdownChanged!: EventEmitter<{
    value: string;
    name: string;
    error?: string;
    label?: string;
  }>;

  // Lifecycle events
  componentWillLoad() {
    this.selectedColor = this.defaultSelectedColor;
    this.el.setAttribute('component-type', this.componentType);
  }

  // Methods
  handleFocus() {
    this.isOpen = true;
  }
  handleBlur() {
    this.isOpen = false;
  }

  // handle input separately from change to allow for live updates in label
  handleInput(event: Event) {
    this.selectedColor = (event.target as HTMLInputElement).value;
  }

  handleChange(event: Event) {
    this.selectedColor = (event.target as HTMLInputElement).value;
    this.nylasFormDropdownChanged.emit({
      value: this.selectedColor,
      name: this.name,
      error: this.errorMessage,
    });
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.code === 'Enter' || event.code === 'Space') {
      event.preventDefault();
      this.inputRef?.click();
    }
  }

  // Event listeners
  @Listen('click', { target: 'document', capture: true })
  handleOutsideClick(event: MouseEvent) {
    // Get the path of the event
    const path = event.composedPath();
    const isClickInside = path.includes(this.el);

    if (!isClickInside && this.isOpen) {
      this.isOpen = false;
    }
  }

  render() {
    return (
      <div class="color-picker-container" part="icp_color-picker" tabindex="0" onKeyDown={e => this.handleKeyDown(e)}>
        <input
          type="color"
          ref={el => (this.inputRef = el as HTMLInputElement)}
          id={this.name}
          name={this.name}
          value={this.selectedColor}
          tabindex="-1"
          onFocus={() => this.handleFocus()}
          onBlur={() => this.handleBlur()}
          onInput={e => this.handleInput(e)}
          onChange={e => this.handleChange(e)}
          onKeyDown={e => this.handleKeyDown(e)}
        />
        <label
          part="icp_color-picker-button"
          htmlFor={this.name}
          class={{ labelBtn: true, open: this.isOpen, error: !!this.errorMessage }}
          aria-haspopup="listbox"
          aria-expanded={this.isOpen ? 'true' : 'false'}
          aria-label={this.name}
        >
          <span class="selected-option" part="icp_color-picker-button-selected-label">
            {this.selectedColor || 'Select a color'}
          </span>
          <span
            class={{
              open: this.isOpen,
              closed: !this.isOpen,
              chevron: true,
            }}
            aria-hidden="true"
          >
            <chevron-icon width="16" height="16" />
          </span>
          {this.errorMessage && <span class="error help-text">{this.errorMessage}</span>}
        </label>
      </div>
    );
  }
}
