import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'forward-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class ForwardIcon {
  @Prop() width: string = '15';
  @Prop() height: string = '15';

  render() {
    return (
      <svg fill="auto" width={this.width} height={this.height} viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m1246.246 64-90.496 90.496 477.952 477.952h-590.848C467.878 632.448.038 1100.416.038 1675.392v109.056h128v-109.056c0-504.576 410.368-914.944 914.816-914.944h590.848l-477.952 478.08 90.496 90.496 632.448-632.576L1246.246 64Z"
          fill-rule="evenodd"
        />
      </svg>
    );
  }
}
