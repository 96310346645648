import { Component, h, Prop, State, Event, EventEmitter, Listen, Watch, Element } from '@stencil/core';
import { sanitize } from '@/utils/utils';

const DefaultPattern = {
  email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  phone_number: /^\+?\d{1,15}$/,
};

const DefaultPlaceholder = {
  email: 'name@example.com',
  phone_number: '1234567890',
};

/**
 * The `input-component` component is a UI component that allows users to input text, email, or phone number values.
 * This component is used in the scheduling form to input text, email and phone number type inputs.
 */

@Component({
  tag: 'input-component',
  styleUrl: 'input-component.scss',
  shadow: true,
})
export class InputComponent {
  /**
   * The host element
   */
  @Element() el!: HTMLElement;
  /**
   * The name of the input. This is used to identify the input when submitting a form.
   */
  @Prop() name: string = 'input';
  /**
   * The default value of the input. This is the value that is displayed when the input is rendered.
   */
  @Prop() defaultValue?: string;
  /**
   * The label of the input. This is displayed above the input.
   */
  @Prop() label: string = '';
  /**
   * The type of the input. This is used to determine the input's behavior.
   * Supported types are 'text', 'email', and 'phone_number'.
   */
  @Prop() type: 'text' | 'email' | 'phone_number' = 'text';
  /**
   * The placeholder of the input. This is displayed when the input is empty.
   */
  @Prop() placeholder: string = DefaultPlaceholder[this.type];
  /**
   * Whether the input is required. If true, the input must have a value when submitting a form.
   * Default is false. If the input is required and the value is empty, an error message is displayed.
   */
  @Prop() required: boolean = false;
  /**
   * Whether the input is read-only. If true, the input cannot be edited.
   * Default is false.
   */
  @Prop() readOnly: boolean = false;
  /**
   * Whether the input should be focused when rendered.
   * Default is false. If true, the input is focused when rendered.
   * Use this to set the focus on the first input in a form.
   */
  @Prop() autoFocus: boolean = false;
  /**
   * The pattern to validate the input value. If the value does not match the pattern, an error message is displayed.
   * Default is null. If the pattern is not set, the pattern is determined by the input type for 'email' and 'phone_number'.
   */
  @Prop() pattern?: RegExp;
  /**
   * The maximum length of the input value. If the value is longer than the maximum length, an error message is displayed.
   * Default is 255.
   */
  @Prop() maxLength: number = 255;
  /**
   * The error message to display when the value does not match the pattern.
   * Default is 'Invalid <field> format.' where <field> is the input label.
   */
  @Prop() patternError: string = '';

  /**
   * This error message is displayed when the input value is empty and the input is required.
   */
  @Prop() requiredError: string = '';

  /**
   * The input value state.
   */
  @State() value!: string;
  /**
   * The error message state.
   */
  @State() error: string = '';

  /**
   * This event is fired when the input value is changed.
   * The scheduling form listens for this event to validate the input value and submit the form.
   * If using outside of the scheduling form, listen for this event to validate the input value
   * and handle the input value change.
   */
  @Event() nylasFormInputChanged!: EventEmitter<{
    value: string;
    name: string;
    label: string;
    type: string;
    error: string;
  }>;

  @Event() nylasFormInputFocused!: EventEmitter<{
    value: string;
    name: string;
  }>;

  @Event() nylasFormInputBlurred!: EventEmitter<{
    value: string;
    name: string;
  }>;

  // Lifecycle methods
  @Watch('defaultValue')
  handleDefaultValueChange(newValue: string) {
    this.value = sanitize(newValue);
    if (this.value) {
      this.validatePattern(this.value);
    }
  }

  componentDidLoad() {
    this.value = sanitize(this.defaultValue || '');
    if (this.type !== 'text' && !this.pattern) {
      this.pattern = DefaultPattern[this.type];
    }

    if (this.value) {
      this.validatePattern(this.value);
    }
  }

  // Event listeners
  /**
   * Listen for the bookingFormSubmitted event to validate the input value when the form is submitted.
   */
  @Listen('bookingFormSubmitted', { target: 'document' })
  handleBookingFormSubmitted(event: CustomEvent) {
    this.validatePattern(this.value);
    if (this.error) {
      event.preventDefault();
    }
  }

  @Listen('formSubmitted', { target: 'document' })
  async handleFormSubmitted(event: CustomEvent) {
    if (this.el?.getAttribute('data-page-styling')) {
      this.validatePattern(this.value);
      if (this.error) {
        event.preventDefault();
      }
    }
  }

  // Methods
  handleInput(e: Event) {
    this.error = '';
    this.value = sanitize((e.target as HTMLInputElement).value);
    this.nylasFormInputChanged.emit({
      value: this.value,
      name: this.name,
      label: this.label,
      error: this.error,
      type: this.type,
    });
  }

  handleBlur() {
    this.nylasFormInputBlurred.emit({
      value: this.value,
      name: this.name,
    });
  }

  handleFocus() {
    this.nylasFormInputFocused.emit({
      value: this.value,
      name: this.name,
    });
  }

  validatePattern(value: string) {
    // Reset error
    this.error = '';
    // Check if the field is required and value is empty
    if (this.required && !value) {
      this.error = this.requiredError || 'This field is required.';
      return;
    }
    // Check if value matches pattern
    if (!this.pattern || (!value && !this.required)) return;

    if (this.pattern.test(value)) {
      this.error = '';
    } else {
      this.error = this.patternError || 'Invalid format.';
    }
  }

  render() {
    return (
      <label part="ic__label" class={{ error: !!this.error }}>
        {this.label && (
          <p>
            <span class="label">{this.label}</span>
            {this.required && <span class="required">*</span>}
          </p>
        )}
        <div part="ic__input_wrapper" class="input_wrapper">
          <input
            type="text"
            name={this.name}
            part="ic__input"
            readOnly={this.readOnly}
            autoFocus={this.autoFocus}
            value={this.value}
            maxLength={this.maxLength}
            placeholder={this.placeholder}
            class={{ error: !!this.error }}
            onInput={e => this.handleInput(e)}
            onFocus={() => this.handleFocus()}
            onBlur={() => this.handleBlur()}
          />
          <slot name="additional-input"></slot>
        </div>
        {this.error && <span class="error help-text">{this.error}</span>}
      </label>
    );
  }
}
