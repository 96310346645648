import { Component, Element, Event, EventEmitter, h, Listen, Prop, State, Watch } from '@stencil/core';

interface DropdownOption {
  labelHTML?: HTMLElement;
  label: string;
  value: string;
}

/**
 * The `input-dropdown` component is a dropdown that allows users to input an option and/or select from a list of options.
 * @part id_dropdown - The dropdown container
 * @part id_dropdown-input - The dropdown button
 * @part id_dropdown-content - The dropdown content
 */
@Component({
  tag: 'input-dropdown',
  styleUrl: 'input-dropdown.scss',
  shadow: true,
})
export class InputDropdown {
  @Element() el!: HTMLElement;

  private inputRef?: HTMLInputElement;
  private optionsRef!: HTMLElement;

  // Props
  /**
   * The name of the dropdown
   */
  @Prop() name!: string;
  /**
   * The options to display in the dropdown
   */
  @Prop() options: DropdownOption[] = [];
  /**
   * The default selected option
   */
  @Prop() defaultInputOption?: DropdownOption;
  /**
   * Should show search input
   */
  @Prop() inputValue!: string;
  /**
   * Show pluralized label for the selected option. This is s tring that is appended to the selected option label as a suffix.
   */
  @Prop() pluralizedLabel: string = '';

  /**
   * This is used to set if the dropdown should be filtered based on the input value.
   * If set to true, the dropdown will be filtered based on the input value.
   */
  @Prop() filterable: boolean = false;

  // States
  /**
   * The selected option
   */
  @State() selectedOption: DropdownOption | null = this.defaultInputOption || null;
  /**
   * The open state of the dropdown
   */
  @State() isOpen: boolean = false;

  /**
   * The typed value in the input
   */
  @State() typedValue: string = '';

  /**
   * The filtered options based on the search value
   */
  @State() filteredOptions: DropdownOption[] = [...this.options];
  /**
   * The aria-activedescendant attribute for the listbox element to indicate the currently active
   * option in the list box to screen readers. The value of aria-activedescendant is the ID of
   * the active option.
   */
  @State() ariaActivedescendant: string = '';

  /**
   * This is used to scroll to the input value.
   */
  @State() shouldAutoScroll: boolean = false;

  // Events
  /**
   * This event is fired when the selected option is changed
   */
  @Event({ bubbles: true, composed: true }) inputOptionChanged!: EventEmitter<{
    value: DropdownOption['value'];
    name: string;
  }>;

  @Watch('options')
  optionsChangedHandler(newValue: DropdownOption[], oldValue: DropdownOption[]) {
    if (newValue === oldValue) {
      return;
    }
    this.filteredOptions = this.getFilteredOptions(newValue);
  }

  @Watch('defaultInputOption')
  defaultSelectedOptionChangedHandler(newValue: DropdownOption, oldValue: DropdownOption) {
    if (newValue?.label === oldValue?.label) {
      return;
    }
    this.selectedOption = newValue;
  }

  @Watch('inputValue')
  inputValueChangedHandler(newValue: string, oldValue: string) {
    if (newValue === oldValue) {
      return;
    }
    this.filteredOptions = this.getFilteredOptions(this.options);
  }

  // Lifecycle methods
  componentWillLoad() {
    this.filteredOptions = this.getFilteredOptions(this.options);
    // Set the selected option to the first option if no option is selected
    if (!this.selectedOption && !!this.defaultInputOption) {
      this.selectedOption = this.defaultInputOption;
    }
    if (!this.selectedOption && this.options.length > 0) {
      this.selectedOption = this.options[0];
    }
  }

  // Methods
  getFilteredOptions(options): DropdownOption[] {
    if (!this.filterable) {
      return options;
    }

    return options.filter(
      option => option?.value?.toString().toLowerCase().includes(this.typedValue?.toLowerCase()) || option?.label?.toLowerCase().includes(this.typedValue?.toLowerCase()),
    );
  }
  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  selectOption(option: DropdownOption): void {
    this.selectedOption = option;
    this.toggleDropdown();
    this.inputOptionChanged.emit({
      value: option.value,
      name: this.name,
    });
  }

  handleOnInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.typedValue = value;
    const optionIndex = this.options.findIndex(option => option.label.toLowerCase().includes(value.toLowerCase()));
    if (optionIndex > -1) {
      this.scrollToViewWithinParent(optionIndex);
    }
    this.inputOptionChanged.emit({
      value,
      name: this.name,
    });
  }

  scrollToViewWithinParent(optionIndex: number) {
    const option = this.options[optionIndex];
    const childElement = this.el.shadowRoot?.getElementById(option.value.toString()) as HTMLLIElement;
    const parentElement = this.optionsRef;

    this.ariaActivedescendant = option.value.toString();
    if (!childElement || !parentElement) return;
    // Scroll child into view within parent
    const childRect = childElement.getBoundingClientRect();
    const parentRect = parentElement.getBoundingClientRect();

    if (childRect.top < parentRect.top) {
      // Child is above the visible area of the parent
      parentElement.scrollTop -= parentRect.top - childRect.top;
    } else if (childRect.bottom > parentRect.bottom) {
      // Child is below the visible area of the parent
      parentElement.scrollTop += childRect.bottom - parentRect.bottom;
    }
  }

  handleSelectButtonKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowDown':
      case 'Enter':
        event.preventDefault();
        if (!this.isOpen) {
          this.toggleDropdown();
        }
        this.inputRef?.focus();
        break;
      case 'Escape':
        this.isOpen = false;
        break;
    }
  }

  handleClick(event: Event): void {
    if (this.isOpen) {
      const value = (event.target as HTMLInputElement).value;
      const optionIndex = this.options.findIndex(option => option.label.toLowerCase().includes(value.toLowerCase()));
      if (optionIndex > -1) {
        setTimeout(() => {
          this.scrollToViewWithinParent(optionIndex);
        }, 10);
      }
    }
  }

  handleListboxKeydown(e) {
    const items = this.filteredOptions;
    const currentIndex = items.findIndex(item => item.value === this.ariaActivedescendant);
    if (e.key === 'ArrowDown' || (e.key === 'Tab' && !e.shiftKey)) {
      e.preventDefault();
      if (currentIndex === items.length - 1) {
        this.ariaActivedescendant = '';
        this.inputRef?.focus();
        return;
      }
      const nextIndex = currentIndex + 1 < items.length ? currentIndex + 1 : 0;
      this.ariaActivedescendant = items[nextIndex].value;
      this.focusOption(nextIndex);
    } else if (e.key === 'ArrowUp' || (e.key === 'Tab' && e.shiftKey)) {
      e.preventDefault();
      if (currentIndex === 0) {
        this.ariaActivedescendant = '';
        this.inputRef?.focus();
        return;
      }
      const prevIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : items.length - 1;
      this.ariaActivedescendant = items[prevIndex].value;
      this.focusOption(prevIndex);
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (this.ariaActivedescendant) {
        this.selectOption(items[currentIndex]);
      }
    } else if (e.key === 'Escape') {
      this.isOpen = false;
    }
  }

  focusOption(index) {
    const option = this.filteredOptions[index];
    if (!option) return; // Guard clause in case index is out of bounds

    const elementId = option.value;
    const element = this.el.shadowRoot?.getElementById(elementId) as HTMLLIElement;

    if (element) {
      element.focus(); // Set focus on the element
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }

  handleComboboxKeyDown(event: KeyboardEvent): void {
    if (event.key === 'ArrowDown' || (event.key == 'Tab' && !event.shiftKey)) {
      event.preventDefault();
      if (!this.isOpen) {
        this.isOpen = true;
        this.shouldAutoScroll = true;
        return;
      }
      this.ariaActivedescendant = this.filteredOptions[0].value;
      this.focusOption(0);
    } else if (event.key === 'ArrowUp' || (event.key === 'Tab' && event.shiftKey)) {
      event.preventDefault();
      this.ariaActivedescendant = this.filteredOptions[this.filteredOptions.length - 1].value;
      this.focusOption(this.filteredOptions.length - 1);
    } else if (event.key === 'Escape') {
      this.isOpen = false;
    }
  }

  // Event listeners
  @Listen('click', { target: 'document', capture: true })
  handleOutsideClick(event: MouseEvent) {
    // Get the path of the event
    const path = event.composedPath();

    // Check if the path includes the host element
    const isClickInside = path.includes(this.el);

    if (!isClickInside && this.isOpen) {
      this.isOpen = false;
    }
  }

  render() {
    return (
      <div class="dropdown" part="id_dropdown">
        <input
          type="text"
          name={this.name}
          id={this.name}
          part="id_dropdown-input"
          class={{ dropbtn: true, open: this.isOpen }}
          value={this.inputValue}
          onClick={(e: Event) => {
            this.toggleDropdown();
            this.shouldAutoScroll = true;
            this.handleClick(e);
          }}
          aria-haspopup="listbox"
          aria-label={this.name}
          aria-expanded={this.isOpen ? 'true' : 'false'}
          onKeyDown={e => this.handleComboboxKeyDown(e)}
          onInput={event => this.handleOnInput(event)}
        />
        {this.isOpen ? (
          <div class="dropdown-content" part="id_dropdown-content" ref={el => (this.optionsRef = el as HTMLElement)}>
            <ul tabindex="-1" role="listbox" aria-label={this.name} aria-activedescendant={this.ariaActivedescendant} onKeyDown={e => this.handleListboxKeydown(e)}>
              {this.filteredOptions.map(option => (
                <li
                  tabindex="0"
                  key={option.value}
                  id={option.value}
                  onClick={() => this.selectOption(option)}
                  role="option"
                  class={{
                    focused: this.ariaActivedescendant === option.value.toString(),
                  }}
                >
                  {option.labelHTML ? option.labelHTML : option.label}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}
