import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'info-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class InfoIcon {
  @Prop() width: string = '14';
  @Prop() height: string = '14';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="currentColor"
          d="M6.5 5.5C6.77614 5.5 7 5.72386 7 6V9C7 9.27614 6.77614 9.5 6.5 9.5C6.22386 9.5 6 9.27614 6 9V6C6 5.72386 6.22386 5.5 6.5 5.5ZM6.50001 4.74907C6.91369 4.74907 7.24905 4.41371 7.24905 4.00003C7.24905 3.58635 6.91369 3.251 6.50001 3.251C6.08633 3.251 5.75098 3.58635 5.75098 4.00003C5.75098 4.41371 6.08633 4.74907 6.50001 4.74907ZM0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5ZM6.5 1.5C3.73858 1.5 1.5 3.73858 1.5 6.5C1.5 9.26142 3.73858 11.5 6.5 11.5C9.26142 11.5 11.5 9.26142 11.5 6.5C11.5 3.73858 9.26142 1.5 6.5 1.5Z"
        />
      </svg>
    );
  }
}
